@import "variables.scss";
@import "bootstrap-overrides.scss";
@import "bootstrap.scss";

h1, h2, h3, h4, h5, h6 {
    &.section-title {
        font-weight: 700;
    }
}

.tab-content {
    background-color: white;
    color: $grey;
    padding: $spacer;
    border-radius: 0 5px 5px 5px;
}

.journey {
    margin-bottom: $spacer;
}

.column-headings {
    margin-bottom: $spacer;
    color: white;
    font-weight: bold;
    & > div {
        padding: $spacer/4 0;
        background-color: $blue;
        text-align: center;
    }
    & > div:first-child {
        // padding-left: 0;
        margin-left: 15px;
    }
    & > div:last-child {
        // padding-right: 0;
        margin-right: 15px;
    }
}

.header {
    padding-top: $spacer * 1;
    .logo {
        padding-right: $spacer;
        img {
            @media (min-width: 576px) { margin-bottom: -100px; }
            @media (min-width: 768px) { margin-bottom: -118px; }
            @media (min-width: 992px) { margin-bottom: -179px; }
            @media (min-width: 1200px) { margin-bottom: -226px; }
            margin-right: 20px;
            max-width: 100%;
        }
    }
    .banner {
        img {
            max-width: 100%;
        }
    }
}

nav.main-tabs {
    & > a {
        color: white;
    }
}

button + button{
    margin-left: $spacer;
}

.btn-primary {
    color: white;
    font-weight: 700;
}

.col-delete button {
    color: grey;
}

.col-return {
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
    span {
        padding-right: $spacer;
    }
    h5, h6 {
        margin-bottom: 0;
        padding-right: $spacer;
    }
}

sub {
    bottom: 0;
}

.col.text-total {
    & > * {
        font-weight: 700;
    }
}

.col {
    // &[class^="col-"] {
    //     flex-grow: 0;
    //     flex-shrink: 0;
    // }
    &.col-delete {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 6%;
    }
    // &.col-origin {
    //     flex-basis: 19%;
    // }
    // &.col-destination {
    //     flex-basis: 19%;
    // }
    // &.col-return {
    //     flex-basis: 10%;
    // }
    // &.col-mode {
    //     flex-basis: 18%;
    // }
    // &.col-distance {
    //     flex-basis: 14%;
    // }
    // &.col-co2 {
    //     flex-basis: 14%;
    // }    
}

.row.totals {
    h5 {
        margin-bottom: 0;
    }
}

.donation-options {
    max-width: 600px;
    margin: 0 auto;
    .row {
        margin-bottom: $spacer;
        & > div {
            display: flex;;
            justify-content: center;
            align-items: center;
            color: $blue;
            font-weight: 700;
        }
    }
    a {
        width: 100%;
    }
}

.footer {
    margin-top: $spacer;
    img {
        width: 100%;
    }
}

.explain-modal {
    color: $blue;
    .emissions-comparison {
        border-left: 1px solid $blue;
        padding: $spacer 0;
        margin-bottom: $spacer;
        div {
            font-weight: bold;
            color: white;
            padding: $spacer $spacer * 2 $spacer $spacer;
            &.your-co2 {
                background-color: $green;
            }
            &.uk-co2 {
                background-color: $blue;
            }
        }
        p {
            padding-left: $spacer;
            padding-top: $spacer / 2;
            font-weight: bold;
        }
    }
}

.co2sub {
    position:relative;
    top:4px;
    font-size:80%;
}

sub {
    top:4px;
}

.table {
    color: $blue;
}