$spacer: 1rem;
$body-bg: $blue;
$body-color: white;

$primary:       $green;
$secondary:     $orange;

$nav-tabs-link-active-bg: white;

$headings-color: $blue;
$headings-margin-bottom: $spacer;